// Here you can add other styles
.customBootstrapTable {
  td {
    padding: 0.3rem;
  }
}

#emoji-close-div {
  display: none !important;
}
